export var licenseUUIDValues=["7a99316a-b2ce-4a5e-b702-86c0615b9f59",// INDIVIDUAL 2MIL
"6fc588fc-c7d9-479a-813f-5d0121e0a5ac",// INDIVIDUAL 1MIL
"c1101b06-8898-4c5f-a497-166c797880f1",// INDIVIDUAL 300k
"2b7c312c-8288-495d-be8f-33cfd1645349",// for ind traders imported from bubble
"4493ddbb-755c-4f8d-b7b5-c70e9eaf324f",// CORP 10MIL
"30d6e1d1-0b18-45ed-8490-7f8c219d34e0",// CORP OWNED
"f28be9cc-acac-4b5f-bb90-bd3167b4ec3d",// CORP TRUST BENEFICIARIES
"39ab03a1-f951-4530-8699-dae8383596a6",// CORP TRUST 10MIL
"296295ea-c4e6-47b7-8141-70d9aa51d695",// CORP INSTITUTION
"d2b26621-4161-43e9-9e74-c350b1e09fcd",// for corp traders imported from bubble
"267524b5-883a-465d-bf91-91f2fc2c9fc1",// MY INDIVIDUAL RETAIL
"f52856d1-fc78-4e9c-97f7-83543b31c8f6",// MY INDIVIDUAL ANGEL
"d77d6f59-6b56-46b3-9713-3a39c82a7fb3",// MY INDIVIDUAL ACCREDITED
"bfd141b3-4445-4e2b-b06d-383198f08ba8",// MY INDIVIDUAL HIGH NET WORTH
"a2e378db-fab7-4817-bd89-60e648d75bb7",// MY CORPORATE RETAIL
"2b19a0b8-82d9-4c3c-82c3-09eaa7a31b43",// MY CORPORATE ACCREDITED
"34ca0ed6-86c4-4105-af14-2473795180f9",// MY CORPORATE HIGH NET WORTH
"9","5b8e499c-7967-4700-8c77-f511f2d7b512",// FUNDNEL INTERNAL SG
"a29dee6c-1222-4d0f-bc51-5c39ad0fedbf"// FUNDNEL INTERNAL MY
];export var licenseUUID={LICENSE_ID_SG_INDIVIDUAL2000000:"7a99316a-b2ce-4a5e-b702-86c0615b9f59",LICENSE_ID_SG_INDIVIDUAL1000000:"6fc588fc-c7d9-479a-813f-5d0121e0a5ac",LICENSE_ID_SG_INDIVIDUAL300000:"c1101b06-8898-4c5f-a497-166c797880f1",LICENSE_ID_SG_CORPORATION10MIL:"4493ddbb-755c-4f8d-b7b5-c70e9eaf324f",LICENSE_ID_SG_CORPORATIONOWNED:"30d6e1d1-0b18-45ed-8490-7f8c219d34e0",LICENSE_ID_SG_TRUSTBENEFICIARIES:"f28be9cc-acac-4b5f-bb90-bd3167b4ec3d",LICENSE_ID_SG_TRUST10MIL:"39ab03a1-f951-4530-8699-dae8383596a6",LICENSE_ID_SG_INSTITUTION:"296295ea-c4e6-47b7-8141-70d9aa51d695",LICENSE_ID_SG_OTHER:"9",LICENSE_ID_SG_FUNDNEL_INTERNAL:"5b8e499c-7967-4700-8c77-f511f2d7b512",LICENSE_ID_MY_FUNDNEL_INTERNAL:"a29dee6c-1222-4d0f-bc51-5c39ad0fedbf",LICENSE_ID_MY_INDIVIDUALRETAIL:"267524b5-883a-465d-bf91-91f2fc2c9fc1",LICENSE_ID_MY_INDIVIDUALANGEL:"f52856d1-fc78-4e9c-97f7-83543b31c8f6",LICENSE_ID_MY_INDIVIDUALACCREDITED:"d77d6f59-6b56-46b3-9713-3a39c82a7fb3",LICENSE_ID_MY_INDIVIDUALHIGHNETWORTH:"bfd141b3-4445-4e2b-b06d-383198f08ba8",LICENSE_ID_MY_CORPORATERETAIL:"a2e378db-fab7-4817-bd89-60e648d75bb7",LICENSE_ID_MY_CORPORATEACCREDITED:"2b19a0b8-82d9-4c3c-82c3-09eaa7a31b43",LICENSE_ID_MY_CORPORATEHIGHNETWORTH:"34ca0ed6-86c4-4105-af14-2473795180f9"};